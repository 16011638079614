@use "../abstracts" as *;

/* NAVIGATION */
header.toolbar {
  .card {
    border-radius: 0;
  }
}

.navHeader {
  width: 100%;

  button {
    padding: 3.75px 7.5px;
    font-size: 14px;
  }
}

.nav-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
  min-height: 100vh;
  flex-basis: 18%;
  background-color: $darkpurple;
  color: $white;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 100;
  height: 100%;

  .wrapper {
    height: 100%;
  }

  &.collapsed {
    footer {
      white-space: nowrap;
      padding-left: 1.25em;
      padding-bottom: 1.25em;
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }
}

.nav-item {
  background-color: $white;
}

.nav-tabs {
  border-bottom: 1px solid $primary !important;
}

.nav-link {
  background-color: none !important;
}

.sidebar-active {
  left: 240px;
}

.sidebar-collapse {
  left: 80px;
}

.navbar-small {
  max-width: 80px;
  min-width: 80px;

  .menu-navs {
    .nav-link,
    span {
      p {
        display: none;
      }
    }
  }
}

.navbar-large {
  max-width: 240px;
  min-width: 240px;
  &.collapsed {
    max-width: 80px;
    min-width: 80px;
    .menu-navs {
      span {
        p {
          display: none;
        }
      }
    }
  }
}

.navbar-small {
  max-width: 80px;
  min-width: 80px;
}

.navbar-small .menu-navs .nav-link p {
  display: none;
}

.navbar-small .menu-navs span p {
  display: none;
}

.menu-navs {
  .dashboard-nav-link {
    color: var(--navlink);
    padding: 0.75rem 1rem;
    font-weight: 600;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      background-color: var(--navlinkbg);
    }
  }

  .nav-link {
    color: var(--navlink);
    padding: 0.75rem 1rem;
    font-weight: 600;

    &:active,
    &:focus,
    &:hover {
      background-color: var(--navlinkbg);
    }

    &.active {
      background-color: var(--navlinkbgActive);
      border-left: 3px solid $lime;
      padding-left: calc(1rem - 3px);
    }
  }
}

.navbar-toggler {
  &:focus {
    box-shadow: none;
  }
}

.navbar-collapse {
  transition: none;

  &.collapse {
    transition: none;
  }
}

.toolbar {
  right: 0;
  top: 0;
  z-index: 100;

  &.collapsed {
    left: 0;
  }

  &.sidebar-collapsed {
    left: 80px;
  }

  .user-image {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
  }

  .online-badge {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 767px) {
  .top-navbar {
    .navbar-collapse {
      &.collapse {
        &.show {
          background: #fff;
          box-shadow: 1px 1px 4px #ddd;
          display: flex;
          padding: 1em;
          position: absolute;
          width: 100%;
          top: 81px;
          left: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .toolbar {
    &.sidebar-collapsed {
      left: 80px;
    }
    &.sidebar-active {
      &.collapsed {
        left: 80px;
      }
    }
  }

  .navbar-small {
    &.expanded {
      max-width: 240px;
      min-width: 240px;

      .menu-navs {
        span {
          p {
            display: block;
          }
        }
      }
    }
  }

  .ms-xl-auto {
    &.navbar-nav {
      background-color: #fff;
      border-radius: 0.25em;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      padding: 0.25em;
      margin-top: 0.5em;
      align-items: flex-end;
      position: absolute;
      right: 0;
      z-index: 1;

      .nav-items {
        text-align: right !important;
        padding: 0.35em 1em;
        width: 100%;

        &:hover {
          background-color: #f5f5f5;
        }

        & > .btn {
          color: #333;
          background-color: transparent !important;
          border-color: transparent !important;
          padding: 0;
          margin: 0 !important;
          text-align: right;
        }

        & > div {
          & > .btn {
            color: #333;
            background-color: transparent !important;
            border-color: transparent !important;
            padding: 0;
            margin: 0 !important;
            text-align: right;
          }
        }
      }
    }
  }

  .navbarOrderBtns {
    position: relative;

    .ms-xl-auto {
      top: 30px;
      min-width: 150px;
    }
  }
}

@media (320px <= width <= 1200px) {
  .navbarSellerBtns,
  .navbarProductBtns {
    position: relative;

    .ms-xl-auto {
      z-index: 2;
      top: 2.5em;
    }
  }
  .navbarProductBtns {
    .ms-xl-auto {
      min-width: 230px;
    }
  }
  .navbarSellerBtns {
    .ms-xl-auto {
      min-width: 180px;
    }
  }
}

@media (max-width: 575px) {
  .navbarSellerBtns, .navbarProductBtns {
    width: 100%;

    .ms-xl-auto {
      top: 0;
      left: 0;
      right: 0;

      .nav-items {
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }

        .btn {
          text-align: center;
        }
      }
    }
  }
}
