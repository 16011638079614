@use "../abstracts/" as *;

.react-grid-item {
  background-color: $white;
  overflow: hidden;
}

@media (max-width: 575px) {
  .react-grid-item {
    .row {
      margin-bottom: 0 !important;
    }

    h5 {
      font-size: 1.15rem;
    }
  }
}

@media (max-width: 769px) {
  .recharts-default-legend {
    visibility: hidden;
  }
}
