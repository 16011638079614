@use "../base" as *;

.return-header-container {
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    color: $mine-shaft;
    margin-left: 10px;
  }
}

.return-header {
  .back-button {
    width: 30px;
    height: 30px;
    background-color: $alto;
    color: $atoll;
    border: none;
  }
}

@media (max-width: 991.98px) {
  .return-header {
    h2 {
      font-size: 1.32rem;
    }
  }
}

@media (max-width: 767.98px) {
  .return-header {
    h2 {
      font-size: 1.125rem;
    }
  }
}
