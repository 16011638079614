@use "../base/" as *;

.discard-btn {
  border: 1px solid #085873;
  border-radius: 5px;
  padding: 6px 12px;
  transition: 0.1s;
  text-decoration: none;

  &:hover {
    background-color: #085873 !important;
    color: white;
  }
}

a {
  .back-buttonn {
    background-color: $alto;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none;
    }

    &:hover {
      background-color: darken($color: $alto, $amount: 10%);
    }

    i {
      color: $atoll;
    }
  }
}

.general-setting-card-link {
  text-decoration: none;
}

.seller-name {
  max-width: 400px;
}

.assign-delivery-method-container .show-error,
.assign-delivery-method-container .show-errors .show-error + .text-muted {
  visibility: hidden;
  display: none;
}

.assign-delivery-method-container .show-errors .show-error {
  color: #dc3545 !important;
  display: block;
  visibility: visible;
}

.show + .dropdown-menu {
  display: block;
}

