@use "../abstracts" as *;
/**
* Base Colors
*/
$white: #ffffff;
$light-grey: #eaeaea;
$black: #000000;
$ice-grey: #f5f5f5;
$ghost-white: #f3f3f9;

/**
* Alert Colors
*/
$tosca: #924045;
$old-rose: #bf858a;
$green-pea: #1f6930;
$bay-leaf: #7fac8a;
$azalea: #fadddf;
$zanah: #d9efde;

/**
* Brand Colors
*/
$shark: #212529;
$geyser: #dee2e6;
$alto: #dddddd;
$atoll: #085873;
$teal-blue: #07495f;
$blurple-blue: #0e0a31; //navbar
$astral: #337ab7;
$st-tropaz: #286090;
$pacific-blue: #00a3c7;
$conifer: #badc58;
$deep-grey: #ccc;
$dove-grey: #707070;
$punch: #dc3545;
$mine-shaft: #333333;
$boulder: #777777;
$pale-sky: #6c757d;
$purple: #867ae9;

.bg-light-gray {
  background-color: $gray-300 !important;
}

.bg-ghost-white {
  background-color: $ghost-white !important;
}

.bg-gray,
.bg-grey {
  background-color: $gray-700 !important;
}

.bg-blue {
  background-color: $mp-blue !important;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-orange {
  background-color: $orange !important;
}
