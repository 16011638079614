.pdfobject-container {
  background-color: white;
  padding: 0;
  border: none;
  margin: 0;
  overflow: auto;
}

.pdfobject {
  margin: 0;
  padding: 0;
  border: none;
  min-height: 600px;
}
