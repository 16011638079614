@use "../abstracts" as *;

.input-with-icon {
  width: 100%;
  height: 2.1875rem;
  border: 1px solid $gray-400;
  display: grid;
  grid-template-columns: 1.875rem 1fr 40px;
  align-content: center;
  background-color: $white;
  border-radius: 4px;

  svg {
    justify-self: center;
    margin-top: 2px;
  }

  input {
    height: 100%;
    border: none;
    outline: none;
    width: 90%;
  }
}

.input-wrapper {
  position: relative;

  .input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 2px 0 2px 10px;
    pointer-events: none;
    display: flex;
    align-items: center;

    &.bi-search {
      right: 0.25rem;
    }
  }
  .input-icon-end2 {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 4px 0 4px 10px;
    right: 2.25rem;
    cursor: pointer !important;
    border-radius: 3px;
    padding: 3px 7px;
    &:hover {
      background-color: #ced4da;
    }
  }
  .input-icon-end {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 4px 0 4px 10px;
    right: 0.25rem;
    cursor: pointer !important;
    border-radius: 3px;
    padding: 1px 4px;
    &:hover {
      background-color: #ced4da;
    }
  }

  .measure-label {
    position: absolute;
    right: 0.75em;
    margin-top: 7px;
    pointer-events: none;
  }

  .input-group-text {
    margin-top: 0;
    right: 0;
    top: 0;
  }
}

.list-wrapper {
  box-shadow: rgb(0 0 0 / 1%) 0px 5px 5px -3px, rgb(0 0 0 / 4%) 0px 8px 10px 1px,
    rgb(0 0 0 / 10%) 0px 3px 14px 2px;
  border-left: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  position: absolute;
  z-index: 10;
  top: 42px;
  max-height: 250px;
  width: 100%;
  overflow-y: auto;

  .list-wrapper-inner {
    .list-group-item {
      border: 0;
      text-align: left;
      width: 100%;
    }
  }

  .list-option {
    border-color: $gray-300;

    &:hover {
      color: $white;
      background-color: $secondary;
      cursor: pointer;
    }
  }
}

.input-clear-icon {
  position: absolute;
  right: 0;
  margin-top: 7px;
  margin-right: 10px;
  cursor: pointer;
}

// hide up and down arrows for input[type="number"] fields
input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
}

.offer-product-search,
.search-autocomplete {
  .position-relative {
    display: flex;

    .input-icon {
      position: absolute;
      right: 0;
      top: 7px;
    }

    .form-control {
      &.with-icon {
        padding-left: 0.75em;
      }
    }
  }
}

.input-group {
  .form-input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;

    &.mb-3 {
      margin-bottom: 0 !important;
    }

    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      + .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.form-check-label {
  &:hover {
    cursor: pointer;
  }
}

.sftp-input {
  width: 48%;

}
