@use "../abstracts" as *;

.img-dropzone {
  border: 2px dashed rgba(0, 0, 0, 0.12);
  border-radius: 0.25em;
  padding: 1em;
  text-align: center;
  color: rgb(107, 114, 128);
  background-color: #f4f7fe;

  .line {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 40%;
      right: 40%;
      top: 51%;
      transform: translateY(-50%);
      height: 1px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .or-text {
      background-color: #f4f7fe;
      padding: 0.5em;
      position: relative;
    }
  }
}

.file-upload-button,
.file-upload-button::-webkit-file-upload-button,
.file-upload-button::-webkit-file-upload-button {
  opacity: 0;
  background-color: $bluegreen;
  border: 0;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  color: $white;
}
