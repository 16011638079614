@use "../abstracts/variables" as *;

.custom__select {
  width: 100%;

  .text-light-gray {
    color: #ccc !important;
  }

  .input-none {
    min-height: 26px;
  }

  &.form-select {
    &.focused {
      border-color: $primary;
      box-shadow: 0 0 0 0.25rem rgba(8, 88, 115, 0.25) !important;
    }
  }

  .select__input-container {
    .select__input {
      &:active,
      &:focus {
        border: 0;
        outline: 0;
      }
    }
  }

  .bi-x {
    color: #777;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: #121212;
      cursor: pointer;
    }
  }

  .dropdown {
    top: 0.7em;
    z-index:1;
    position:relative;

    .dropdown-options {
      max-height: 300px;
      overflow-y: auto;
      // negative offset left and right based on form-select padding
      left: -15px;
      right: -2.25rem;

      .option {
        margin: 0.125em 0;
        padding: 0.25em 0.5em;

        &:hover {
          background-color: #e9ecef;
          cursor: default;
        }
      }
    }
  }

  .border-right {
    border-right: 1px solid #ccc;
  }
}

.form-select[aria-disabled="true"] {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #7d7d7d;

  &:focus {
    border-color: #dee2e6 !important;
    box-shadow: none !important;
    outline: 0;
  }
}
