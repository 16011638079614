// Reset the webiste

.headings {
  &__display {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.displays-container p:nth-child(odd) {
  margin-bottom: 0;
}

.displays-weights {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.text-alignment {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.buttons {
  button {
    margin-right: 10px;
  }
}

.inputs {
  input,
  label {
    margin-top: 10px;
  }

  input {
    width: 50%;
  }

  .limited-text-area-container {
    width: 50%;
  }
}
