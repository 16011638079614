@use "../abstracts/" as *;

.animation-container {
  position: relative;
  margin: auto;
}

.dot {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
  left: 0;
  top: 0;
}

.dot:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $gray-900;
  border-radius: 100%;
  animation: spinner-animation 1s infinite ease-in-out both;
}

.dot-2 {
  transform: rotate(45deg);
}

.dot-3 {
  transform: rotate(90deg);
}

.dot-4 {
  transform: rotate(135deg);
}

.dot-5 {
  transform: rotate(180deg);
}

.dot-6 {
  transform: rotate(225deg);
}

.dot-7 {
  transform: rotate(270deg);
}

.dot-8 {
  transform: rotate(315deg);
}

.dot-2:before {
  animation-delay: -0.875s;
}

.dot-3:before {
  animation-delay: -0.75s;
}

.dot-4:before {
  animation-delay: -0.625s;
}

.dot-5:before {
  animation-delay: -0.5s;
}

.dot-6:before {
  animation-delay: -0.375s;
}

.dot-7:before {
  animation-delay: -0.25s;
}

.dot-8:before {
  animation-delay: -0.125s;
}

@keyframes spinner-animation {
  0%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

.ripple {
  width: 2rem;
  height: 2rem;
  margin: 2rem;
  border-radius: 50%;
  border: 0.3rem solid #074b62;
  transform: translate(50%);
  animation: 1s ripple ease-out infinite;
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}
