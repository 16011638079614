.show-offers-dropdown {
  display: block;
  right: 50%;
}

.form-separator {
  width: calc(100% + 32px);
  height: 1px;
  margin-left: -16px;
  margin-right: -16px;
  border: 1px solid #ededed;
}

.category-group {
  &__body {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  &__root {
    padding: 0.3rem;
    width: 100%;
    &:hover {
      cursor: pointer;
      background-color: #f1f5f7;
    }
  }
}
