@use "../abstracts" as *;

// BUTTONS: Overrides of default Bootstrap colors
.btn-primary {
  background-color: var(--primaryButtonBGColor) !important;
  border-color: var(--primaryButtonBorderColor) !important;

  &:active,
  &:focus,
  &:hover {
    background-color: var(--primaryButtonBGHoverColor) !important;
    border-color: var(--primaryButtonBorderHoverColor) !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(8, 88, 115, 0.25) !important;
  }

  &:disabled {
    background-color: $gray-400 !important;
    border-color: $gray-400 !important;
    color: $gray-700 !important;
  }
}

.btn-secondary {
  background-color: var(--secButtonBorderColor) !important;
  border-color: var(--secButtonBorderColor) !important;

  &:active,
  &:focus,
  &:hover {
    background-color: var(--secButtonBGHoverColor) !important;
    border-color: var(--secButtonBorderHoverColor) !important;
    color: var(--secButtonTextHoverColor) !important;
  }
  &:disabled {
    background-color: $gray-400 !important;
    border-color: $gray-400 !important;
    color: $gray-700 !important;
  }
}

.btn-warning {
  color: $gray-800;

  &:active,
  &:focus,
  &:hover {
    color: $gray-900;
  }
}

.btn-outline-primary {
  color: var(--primaryButtonBGColor);
  border-color: var(--primaryButtonBGColor);

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: $white !important;
    background-color: var(--primaryButtonBGColor);
    border-color: var(--primaryButtonBGColor);
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(8, 88, 115, 0.25) !important;
  }
}

.btn-outline-secondary {
  color: $secondary;
  border-color: $secondary;

  &:active,
  &:focus,
  &:hover {
    color: $white;
    background-color: $secondary;
    border-color: $secondary;
  }
}

.btn-outline-success {
  color: $success;
  border-color: $success;

  &:active,
  &:focus,
  &:hover {
    color: $white;
    background-color: $success;
  }
}

.btn-outline-info {
  color: $info;
  border-color: $info;

  &:active,
  &:focus,
  &:hover {
    color: $white;
    background-color: $info;
  }
}

.btn-outline-warning {
  color: $warning;
  border-color: $warning;

  &:active,
  &:focus,
  &:hover {
    color: $white;
    background-color: $warning;
  }
}

.btn-outline-danger {
  color: $danger;
  border-color: $danger;

  &:active,
  &:focus,
  &:hover {
    color: $white;
    background-color: $danger;
  }
}

.btn-clean,
.btn-link {
  background-color: transparent;
  border-color: transparent;
  color: $dark;

  &:active,
  &:focus,
  &:hover {
    outline: 0; // removing BS default outline and box-shadows
    box-shadow: none;
  }
}

.btn-light {
  background-color: $gray-200 !important;
  color: #000 !important;
}

.btn-block {
  width: 100% !important;
}

.weekly-elements {
  &.weekly-on-element {
    background-color: var(--primaryButtonBGColor) !important;
    opacity: 1;
    color: #ffffff !important;
  }
  &:not(.weekly-on-element) {
    &:disabled {
      color: #212529;
      background-color: #e9ecef;
      border-color: #ced4da;
      &:hover {
        cursor: default;
      }
    }
  }
}
