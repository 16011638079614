@use "../abstracts" as *;

.manage-categories-header {
  position: relative;

  .create-category-popup {
    width: 450px;
    height: 500;
    position: absolute;
    top: 70px;
    right: 0;
    z-index: 1;

    .triangle {
      width: 60px;
      height: 60px;
      box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.2);
      transform: rotate(45deg);
      //background: $white;
      margin-bottom: -60px;
      margin-right: 20px;
    }

    .create-category-container {
      //background-color: $white;
      width: 400px;
      min-height: 350px;
      z-index: 2;
      box-shadow: 0px 8px 16px rgba(39, 39, 39, 0.2);
      padding: 20px;
      margin-left: 80px;
      position: relative;

      .buttons-container {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }

      .show-errors {
        .show-error {
          color: $danger !important;
        }
      }
    }
  }
}

// Board (right container)
.board {
  // Category
  .collapse-card {
    background-color: rgba(64, 81, 137, 0.05);
    border: 1px solid rgba(64, 81, 137, 0.05);

    .nested-list {
      .list-group-item {
        background-color: rgba(64, 81, 137, 0.05);
        border-color: rgba(64, 81, 137, 0.08);
      }
    }

    .subcategory-hidden {
      display: none !important;
    }
  }

  .category {
    transition: 0.5s;

    .cross-button {
      // @include square-button;
    }

    .left-container {
      width: 60%;

      .texts {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 115px;
        justify-content: space-between;
      }

      .category-title {
        width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .right-container {
      width: 40%;
    }

    .icon-button {
      @extend %icon-button !optional;
    }

    .edit-category {
      input {
        height: 20px;
      }

      .category-name-input {
        width: 90%;
      }

      .commission-fee-input {
        width: 80px;
      }

      .discard-button {
        margin-right: 10px;
      }

      .discard-button,
      .save-button {
        height: 30px;
      }
    }
  }

  // Subcategory

  .subcategoryy {
    height: 50px;

    .icon-button {
      @extend %icon-button !optional;
    }

    .left-container {
      width: 60%;
    }

    .right-container {
      width: 40%;
    }

    .subcategory-title {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .edit-subcategory {
      input {
        height: 20px;
      }

      .show-errors {
        .show-error {
          color: $danger !important;
        }
      }
    }
  }

  // Create Subcategory

  .create-subcategory-container {
    position: absolute;
    bottom: 10px;
    left: 13px;

    .cross-button {
      @include square-button;
    }
  }

  .hide-add-category {
    display: none !important;
  }

  .popup-create-subcategory {
    width: 456px;
    height: 180px;
    z-index: 2;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    .triangle {
      width: 60px;
      height: 60px;
      box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.2);
      transform: rotate(45deg);
      background: $white;
      margin-bottom: -40px;
      z-index: 3;
    }

    .create-subcategory-container {
      //  background-color: $white;
      width: 290px;
      height: 170px;
      z-index: 3;
      box-shadow: 0px 8px 16px rgba(39, 39, 39, 0.2);
      padding: 20px;
      margin-left: 80px;
    }

    input {
      height: 30px;
    }

    .show-errors {
      .show-error {
        color: $danger !important;
      }
    }

    .buttons-container {
      position: absolute;
      right: 20px;
    }
  }

  .parent-container-subcategories {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
  }
}

// the page
.manage-product-attributes {
  // Categires area (left panel)
  .categories-area {
    height: calc(100vh - 23em);

    .categories-card {
      //height: calc(100vh - 23em);

      .categories-folders {
        height: 100vh;

        .folder-tree {
          width: 100%;

          .folder--label {
            &:hover {
              font-weight: 500;
            }
          }
        }
      }

      .category-tree-loader {
        position: relative;
        height: 100%;

        .tree-loader {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }

    .category-tree {
      .category {
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;

        &.active {
          font-weight: 600;
        }
      }

      .level-0 {
        height: calc(100vh - 25em);
        // set scrollbar to only appear when its content overflows the defined height.
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .btn-clean {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0.85rem;
    }
  }

  .attributes-area {
    margin-top: 3.5em;

    .attributes-container {
      padding: 2em;
      min-height: 300px;
    }

    .list-group {
      &:last-child {
        margin-bottom: 0.5em;
      }
    }
  }

  @media (min-width: 992px) {
    .categories-area {
      position: -webkit-sticky; // Support for Safari and iOS
      position: sticky;
      top: 100px;
    }
  }

  @media (max-width: 992px) {
    .attributes-area {
      margin-top: 8rem;
    }
  }
}

.not-allowed {
  cursor: not-allowed;
}

.tree {
  line-height: 1.5;

  .folder-tree {
    padding-left: 20px;

    .folder--label {
      display: flex;
      align-items: center;
      width: 100%;

      span {
        margin-left: 5px;
        max-width: 300ch;
      }
    }
  }

  .file-tree {
    padding-left: 20px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
}

.category-tree {
  margin-top: 1em;
  padding: 0.5em 0;

  .category {
    margin-top: 0.25em;

    &.active,
    &:hover {
      background-color: $gray-100;

      label {
        color: $secondary;
      }
    }

    small {
      &:hover {
        cursor: default;
      }
    }
  }

  .category-name {
    &:hover {
      color: $secondary;
      cursor: pointer;
    }
  }

  .is-child {
    position: relative;

    .category {
      margin-top: 0;

      .category-name-wrapper {
        position: relative;
      }
    }

    [class^="level-"] {
      & + .show-more-wrapper {
        margin-left: 1.75em !important;
      }
    }

    & > * {
      margin-left: 1.25em;
    }
  }

  .show-more-wrapper {
    border-top: 1px solid #ddd;
    padding-top: 0.25em;
  }
}
