//@use "../abstracts";
// Mixin to prefix several properties at once
// @param {String} $property - property name
// @param {*} $value = property value
// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  // output standard non-prefixed declaration
  #{$property}: $value;
}

@mixin h1($font-weight: 700, $color: $black) {
  font-size: 2rem;
  font-weight: $font-weight;
  color: $color;
  margin: 0;
}

@mixin h2($font-weight: 700, $color: $black) {
  font-size: 1.75rem;
  font-weight: $font-weight;
  color: $color;
  margin: 0;
}

@mixin h3($font-weight: 700, $color: $black) {
  font-size: 1.51rem;
  font-weight: $font-weight;
  color: $color;
  margin: 0;
}

@mixin h4($font-weight: 700, $color: $black) {
  font-size: 1.32rem;
  font-weight: $font-weight;
  color: $color;
  margin: 0;
}

@mixin h5($font-weight: 700, $color: $black) {
  font-size: 1.25rem;
  font-weight: $font-weight;
  color: $color;
  margin: 0;
}

@mixin text($font-weight: 300, $color: $shark) {
  font-size: 1rem;
  font-weight: $font-weight;
  color: $color;
  margin: 0;
}

@mixin normal($font-weight: 400, $color: $black) {
  font-size: 0.875rem;
  font-weight: 700;
  color: $color;
  margin: 0;
}

@mixin small($font-weight: 500, $color: $black) {
  font-size: 0.75rem;
  font-weight: $font-weight;
  color: $color;
  margin: 0;
}

@mixin text-error {
  font-size: 0.75rem;
  font-weight: 400;
  //color: $old-rose;
}

@mixin primary-button($height: 37px, $width: 78px, $font-size: 16px) {
  //background-color: $atoll;
  border-radius: 4px;
  //color: abstracts.$white;
  font-weight: 400;
  height: $height;
  width: $width;
  font-size: $font-size;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  &:hover {
    //background-color: $teal-blue;
  }

  &:disabled {
    //background-color: $alto;
    //color: $dove-grey;
    cursor: not-allowed;
  }
}

@mixin alternate-button($height: 37px, $width: 78px, $font-size: 16px) {
  height: $height;
  width: $width;
  font-size: $font-size;
  //border: 1px solid $atoll;
  border-radius: 4px;
  //@include: $atoll;
  //@extend: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
  margin-right: 5px;
}

@mixin delete-button($height: 37px, $width: 78px, $font-size: 16px) {
  height: $height;
  width: $width;
  font-size: $font-size;
  border: none;
  border-radius: 4px;
  //color: $white;
  //background-color: $punch;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
  margin-right: 5px;

  &:disabled {
    //background-color: $azalea;
    cursor: not-allowed;
  }
}

@mixin square-button {
  width: 12px;
  height: 12px;
  padding: 0;
  background-color: black;
  border: none;
  //color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
}
