.custom-fields-container {
  position: -webkit-sticky; // support for Safari and iOS devices
  position: sticky;
  top: 50px;

  .custom-field-card {
    width: 95%;
    height: 37px;
  }
}

.custom-fields {
  &__rows {
    a {
      text-decoration: none;
    }
  }

  .custom-fields-information {
    padding-left: 0;
  }

  .custom-field-row {
    height: 55px;
    cursor: pointer;
    width: 100%;
  }

  .custom-field-badge {
    //background-color: $pale-sky;
    padding: 5px;
    border-radius: 10px;
    // color: $white;
    font-weight: 700;
  }
}
