.floating-card {
  position: absolute;
  right: 0;
  z-index: 1;
  min-width: 450px;

  &__triangle {
    width: 30px;
    height: 30px;
    box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.2);
    transform: rotate(45deg);
    margin-bottom: -30px;
  }

  &__container {
    z-index: 2;
    box-shadow: 0px 8px 16px rgba(39, 39, 39, 0.2);
  }

  .modal-header {
    background-color: #006178;
    border-radius: 0;
    color: #fff;
    position: relative;
  }

  .caret {
    position: absolute;
    top: -10px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ccc;

    &:after {
      content: " ";
      position: absolute;
      right: -9px;
      top: 2px;
      border-top: none;
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
      border-bottom: 9px solid #fff;
    }
  }

  .modal-content {
    .caret {
      right: 40px;
    }
  }
}
