@use "../abstracts/" as *;

.selected-row {
  background-color: #f1f5f7 !important;
  &:first-child {
    border-top-color: #dee2e6;
    border-top-width: 1px;
  }
}

.calendar-card {
  width: 335px;
  height: 430px;
  position: absolute;
  z-index: 20;
  bottom: -430px;
}

.table-floating-card {
  position: absolute;
  left: 0;
  z-index: 1;

  &__triangle {
    width: 30px;
    height: 30px;
    box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.2);
    transform: rotate(45deg);
    margin-bottom: -30px;
  }

  &__container {
    z-index: 2;
    box-shadow: 0px 8px 16px rgba(39, 39, 39, 0.2);
  }
}

.table-wrapper {
    overflow-x:auto;
  .clear-selections {
    &:hover {
      cursor: pointer;
    }
  }
}

table {
  td {
    vertical-align: middle;
  }
}

.table {
  &.table-hover {
    & > tbody {
      vertical-align: middle !important;

      & > tr {
        &:hover {
          background-color: #f1f5f7;
          --bs-table-accent-bg: #f1f5f7;
        }
      }
    }
  }
  &.table-bordered {
    tbody {
      th {
        font-weight: 400;
        background-color: $gray-200;
      }
    }
  }

  .text-capitalize {
    font-weight: 600;
    min-width: 80px;
    white-space: nowrap;

    &:last-of-type {
      min-width: 80px;
    }

    &.text-truncated {
      text-align: left;
      font-weight: 400;
    }

    &.sortable {
      &:hover {
        cursor: pointer;

        .bi {
          color: #333;
        }
      }
    }

    .bi {
      color: #b3b3b3;
      transition: color 0.5s;

      &:not(.bi-filter-left) {
        color: #333;
      }
    }
  }
}

.row-element {
  &:last-of-type {
    > div,
    .dropdown-wrapper {
      text-align: center; // center all action buttons
    }
  }

  // remove left and right padding for links in tables
  a {
    padding-left: 0;
    padding-right: 0;

    &:active,
    &:focus,
    &:hover {
      color: $link-hover-color;
    }
  }
}

.filter-floating-card {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1;
  min-width: 500px;

  &__triangle {
    width: 30px;
    height: 30px;
    box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.2);
    transform: rotate(45deg);
    margin-bottom: -30px;
  }

  &__container {
    z-index: 2;
    box-shadow: 0px 8px 16px rgba(39, 39, 39, 0.2);
    width: 500px;
  }

  .modal-content {
    border-radius: 0;

    .modal-header {
      background-color: #006178;
      border-radius: 0;
      color: #fff;
    }

    .caret {
      position: absolute;
      top: -10px;
      left: 9px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ccc;

      &:after {
        content: " ";
        position: absolute;
        left: -9px;
        bottom: -10px;
        border-top: none;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        border-bottom: 9px solid #fff;
      }
    }
  }
}

.btn {
  + .btn {
    + .filter-floating-card {
      left: 200px;
    }
  }
}

div {
  + .btn {
    + .filter-floating-card {
      left: 255px;
      top: 45px !important;
    }
  }
}

.presets-table,
.products-table {
  .file-size-format {
    right: 20%;
    top: 7px;
  }
}
.product-info {
  overflow: visible;
}
.product-details {
  word-break: break-word;
  white-space: normal;
  .product-header {
    width: 19vw;
    display: flex;
  }
  .product-description {
    width: 19vw;
  }
  .product-extra-info {
    width: 19vw;
  }
}

.table-placeholder {
  th {
    &:last-child {
      width: 100px;
      text-align: center;
    }

    &:only-child {
      text-align: left;
    }
  }
}

@media (390px <= width <= 769px) {
  .btn {
    & + .btn {
      & + .filter-floating-card {
        left: 0;
        min-width: 100%;
      }
    }
  }

  .filter-floating-card {
    .modal-content {
      .caret {
        left: 210px;
      }
    }
  }

  .table-pagination-info {
    p {
      text-align: center;
    }
  }

  .pagination {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .filter-floating-card {
    top: 89px;

    .modal-content {
      .caret {
        left: 2px;
      }
    }
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
