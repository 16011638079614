.vertical-timeline {
  padding: 0.5em 0 !important;

  &:before {
    background: #ced4da !important;
    width: 2px !important;
  }

  .vertical-timeline-element {
    margin: 2.25em 0 !important;

    .vertical-timeline-element-icon {
      background: #085873 !important;
    }
  }
}

.vertical-timeline-element-content {
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 0 !important;

  &:after {
    display: inline;
  }

  b {
    font-weight: 500;
  }

  .vertical-timeline-element-date {
    padding: 0 !important;
    opacity: 0.5 !important;
  }
}

.msg-date-heading {
  display: flex;
  padding: 0 0.5rem;
  width: 100%;

  &:before,
  &:after {
    content: "";
    border-top: 1px solid #ddd;
    flex-grow: 1;
    margin: 0 1rem;
  }
}
