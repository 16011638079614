.logs-table {
  thead {
    tr {
      :last-child {
        text-align: left;
      }
    }
  }

  tbody {
    tr {
      :last-child {
        text-align: left;
      }
    }
  }
}

.filter-badges {
  background-color: #fff3cd;
  border-color: #856404;
  color: #856404;
  border-radius: 5px;
  padding: 0.25rem 0.75rem;
}

.navigation-arrow-button-left,
.navigation-arrow-button-right {
  width: 35px;
  height: 35px;
  //color: $atoll;
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.navigation-arrow-button-left {
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.navigation-arrow-button-right {
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.navigation-button {
  width: 35px;
  height: 35px;
  //color: $atoll;
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.navigation-button-active {
  //background-color: $atoll;
  //color: $white;
  border: none;
}

.box-wrap {
  inline-size: 350px;
  overflow-wrap: break-word;
}
