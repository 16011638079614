@use "./abstracts" as *;

// Bootstrap and Font Awesome Icons
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

:root {
  --navbg: #0e0a31;
  --navlink: #ffffff;
  --navlinkbg: #286090;
  --navlinkbgActive: #00a3c7;
  --mainbg: #f5f5f5;
  --mainlink: #00a3c7;
  --mainlinkHoverBG: #00a3c7;
  --primaryButtonBGColor: #074b62;
  --primaryButtonBorderColor: #074b62;
  --primaryButtonBorderHoverColor: #0b7599;
  --primaryButtonBGHoverColor: #0b7599;
  --primaryButtonTextColor: #ffffff;
  --primaryButtonTextHoverColor: #ffffff;
  --secButtonBGColor: #00a3c7;
  --secButtonBorderColor: #00a3c7;
  --secButtonBorderHoverColor: #00a3c7;
  --secButtonBGHoverColor: #ffffff;
  --secButtonTextColor: #ffffff;
  --secButtonTextHoverColor: #00a3c7;
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Barlow", sans-serif;
  background-color: var(--mainbg);
}

.logo-container {
  height: auto;
  max-height: 125px;

  img {
    width: 100%;
    max-height: 125px;
    object-fit: contain;
  }
  img.logo-small {
    max-height: 125px;
  }
}

.navHeader {
  width: 100%;
}

.navHeader button {
  padding: 3.75px 7.5px;
  font-size: 14px;
}

.nav-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
  min-height: 100vh;
  flex-basis: 18%;
  background-color: var(--navbg);
  color: $white;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 100;
  height: 100%;

  .wrapper {
    height: 100%;
  }
}

.thematic-break {
  margin: 0 0.5rem;
  opacity: 80% !important;
  width: calc(100% - 1rem);
  background-color: $astral;
}

.menu-navs {
  .dashboard-nav-link {
    color: var(--navlink);
    padding: 0.75rem 1rem;
    font-weight: 600;
    min-height: 48px;
  }
  .dashboard-nav-link:hover {
    background-color: var(--navlinkbg);
  }
}

.menu-navs {
  .nav-link {
    color: var(--navlink);
    padding: 0.75rem 1rem;
    font-weight: 600;
    min-height: 48px;
  }
  .nav-link:hover {
    background-color: var(--navlinkbg);
  }
  .nav-link.active {
    background-color: var(--navlinkbgActive);
    border-left: 3px solid $conifer;
    padding-left: calc(1rem - 3px);
  }
}

.main-container {
  margin-bottom: 5em;
  // background-color: var(--mainbg);
  a {
    color: var(--mainlink);
  }
}

.content-container {
  padding: 1.5rem;
  width: 100%;
  min-height: 100%;
  min-height: 100vh;
}

.card {
  width: 100%;
  background-color: $white;
  border: 1px solid $gray-300;
}

.div-table {
  font-size: 0.875rem;

  &-tablehead {
    font-weight: bold;
    padding: 0 0 1rem 0;
    font-size: 1rem;
    border-bottom: 1px solid $gray-500;
  }

  &-row {
    border-bottom: 1px solid $gray-200;
  }

  .settings-container {
    width: 100%;
    overflow: auto;
  }

  .settings-item {
    h4 {
      font-size: 1.25rem;
      color: $gray-200;
      font-weight: 600;
    }

    p {
      margin-bottom: 0;
      font-size: 0.875rem;
    }

    &-icon-bg {
      background-color: #eee;
    }

    svg {
      width: 18px;
      height: 18px;
      stroke: $gray-200;
    }

    svg path {
      fill: $gray-200;
    }

    &-table-n {
      flex-basis: 5%;
      padding: 0 1rem 0 0;
    }
  }

  .blue-icons a {
    color: #0d6efd !important;
  }

  .dropdown-icon {
    stroke: $pacific-blue;
  }

  .button-primary,
  .button-secondary {
    background-color: $blurple-blue;
    border: 0 solid $blurple-blue;
    padding: 0.5rem 1.5rem;
    border-radius: 2px;
    color: $white;
  }

  .button-secondary {
    background-color: $gray-600;
    border: 0 solid $white;
  }

  .breakWord {
    word-wrap: break-word;
  }

  .navBrand {
    font-size: 14px;
  }

  .topNav {
    padding: 15px;
    border-bottom: 1px solid #e7e7e7;
    background-color: #f8f8f8;
    color: #777;
  }

  .loginBtn,
  .logoutBtn {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;

    &:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
      text-decoration: none;
    }
  }

  .logoutBtn {
    float: right;
  }

  .overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    z-index: 2;

    .overlay-content {
      position: absolute;
      top: 40%;
      left: 50%;
      font-size: 18px;
      text-align: center;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .overlay-heading {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
      color: #444;
    }

    .overlay-message {
      color: #5f5f5f;
    }

    .overlay-action {
      margin-top: 10px;
    }
  }

  button {
    margin: 0.2em;
    padding: 0.2em;
  }

  .sub-title {
    font-weight: bold;
    font-size: 16px;
    margin: 0.5em;
    border-bottom: 1px solid lightgrey;
  }

  .list-row-header {
    font-weight: bold;
    margin: 0.5em 0;
  }

  .list-row:nth-of-type(odd) {
    background-color: #ddd;
  }

  .list-row-cell {
    display: inline-flex;
    margin: 0.5em 1.2em;

    &.user-role,
    &.user-delete {
      width: 5%;
    }

    &.user-email {
      width: 35%;
    }

    .user-row-select {
      select {
        height: 30px;
        border-color: lightgrey;
      }
    }

    &.user-role-level div {
      margin-right: 10px;
    }
  }

  .list-users .list-row-cell {
    width: 15%;
  }

  .btn-add-product,
  .btn-add-offer,
  .btn-edit-offer,
  .btn-edit-seller-product {
    float: right;
  }
  .product-descr {
    width: 70%;
  }

  .list-details.abs-loc {
    position: absolute;
    top: 350px;
    left: 20px;
    width: 95%;
    background-color: #eee;
    z-index: 2;
  }

  .list-row-cell.attr-name {
    width: 180px;
  }

  .list-row-cell.attr-value {
    max-width: 75%;
    min-width: 200px;
  }

  .offer-list .prod-descr,
  .offer-list .offer-descr {
    width: 35%;
  }

  .offer-details li,
  .seller-product-details li,
  .seller-product-details .default-value {
    color: #aaa;
  }

  .offer-details li.active,
  .seller-product-details li.active {
    color: black;
  }

  .seller-product-details .default-value {
    max-width: 400px;
  }

  .contain {
    max-width: 1170px;
    margin-left: 1px;
    margin-right: auto;
    padding: 5em;
  }

  @media (min-width: 600px) {
    .contain {
      padding: 0;
    }
  }

  .wrapper {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 1);
    width: 100%;
  }

  .wrapper > * {
    padding: 1em;
  }

  @media (min-width: 700px) {
    .wrapper {
      display: grid;
      grid-template-columns: auto auto auto;
    }
    .wrapper > * {
      padding: 10px;
    }
  }

  .form {
    background: #fff;
    margin: 1em;
    padding: 10px;
  }

  .error-message {
    font-size: 12;
    color: red;
  }

  @media (min-width: 1800px) {
    .settings-container {
      width: 80%;
    }
  }

  .btn-primary,
  .form-check-input:checked {
    background-color: #085873;
    border-color: #085873;

    &:hover,
    &:active,
    &:focus {
      background-color: #07495f !important;
      border-color: #07495f;
    }
  }
}

.btn-left-arrow {
  width: 30px;
  height: 30px;
  border-radius: 0;
  background-color: #dddddd;
  padding: 0;
  border: none;
}

.settings-item-icon-bg {
  background-color: $gray-300;
  width: 50px;
  height: 50px;
}
.file-upload-button::-webkit-file-upload-button,
.file-upload-button::-webkit-file-upload-button {
  background-color: $gray-300;
  border: none;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  color: #ffffff;
}
.btn-outline-cancel {
  border-color: $teal-blue;
}

.action-dropdownmenu {
  cursor: pointer;
  .delete-disabled {
    cursor: none;
    color: $gray-300;
  }
  .action-card {
    width: 8rem;
    position: absolute;
    left: -10%;
    margin-left: -3.5rem;
    z-index: 100;
    background-color: #ffffff;
  }
  a {
    color: #212529;
  }
  a:active {
    color: #ffffff;
  }
  .dropdown-item.delete-disabled {
    &:hover,
    &:active {
      background-color: #ffffff;
      color: $gray-300;
    }
  }
}
//for dropdown element, might remove later
.action-dropdownmenu {
  .action-dropup {
    bottom: 110%;
  }
}

.action-dropdownmenu {
  .action-dropdown {
    top: 110%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.dialog-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000003c;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 200;

  .dialog-header {
    background-color: #0e0a31;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.chips {
  border-bottom: 1px solid #ced4da;

  .chip {
    background-color: #ddd;
    &:focus {
      background-color: #bbb !important;
    }
  }
  .selected {
    background-color: #bbb !important;
  }

  .input-wrapper {
    flex: 1 1 0%;

    input {
      outline: 0px !important;
      box-shadow: none;
      border: 0 !important;
      padding-left: 0;
      padding-bottom: 0;
    }
  }
}
.main-container {
  .no-decoration {
    a,
    a:hover {
      text-decoration: none;
      background: transparent;
    }
  }
  .dropdown-menu {
    .no-decoration {
      a {
        &:active,
        &:hover {
          color: #343a40 !important;
        }
      }
    }
  }
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
}

.general-settings > a {
  flex: 0 50%;
}

.show-errors .show-error,
.show-errors i {
  color: red !important;
}
.link-decoration {
  a {
    text-decoration: underline;
    color: var(--mainlink);
  }
  a:hover {
    color: var(--mainlinkHoverBG);
  }
}

.general-settings-container {
  a {
    text-decoration: none;
  }
}

@media (min-width: 1200px) {
  .general-settings > a {
    flex: 0 33%;
  }
}

.weekly-elements {
  border: 1px solid var(--primaryButtonBGColor);
  color: var(--primaryButtonBGColor);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  background-color: $white !important;
  cursor: pointer;
  margin-right: 0.25rem;
}

.bg-gray {
  background-color: #777;
}

.bg-ligth-gray {
  background-color: #e9ecef;
}

.text-gray {
  color: #777;
}

.no-decoration .card {
  border-color: #ccc;
  border-color: transparent;
  border-radius: 0.8em;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
.select-box {
  width: 100%;
  height: 100px;
  background-color: $gray-200;
  margin-top: 6px;
  text-align: center;
  line-height: 100px;
}

.logo-container .logo-small {
  width: 80px;
  height: auto;
}

.form-control.custom-css::placeholder {
  opacity: 0.4;
}
.card-title {
  font-weight: 600 !important;
}

.back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.dropdown-item span {
  text-transform: capitalize;
}

.archived-users-temp .shadow-sm {
  box-shadow: none !important;
}
.general-setting-card-link .card-title,
.general-setting-card-link .bi,
.administration-card-link .card-title,
.administration-card-link .bi {
  color: #085873;
}
.select-file-box {
  width: 100%;
  height: 200px;
  border: 2px dashed #c6c6c6;
  background-color: #f4f7fe;
}
.weekly-elements:last-child {
  margin-right: 0;
}
.avatar-div {
  width: 100px;
  height: 100px;
}

.tooltip-custom {
    position: relative;
    display: inline-block;
  .tooltip-text {
    font-size: $tooltip-font-size;
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 3px 7px;
    z-index: 100;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    width: fit-content;
    white-space: nowrap;
    &::after {
      content: "";
      position: absolute;
      border-style: solid;
    }
  }
  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  &.left .tooltip-text {
    left: -8px;
    bottom: 50%;
    margin-right: 15px;
    transform: translate(-100%, 50%);
    &::after {
      top: 50%;
      right: -4px;
      transform: translateY(-50%);
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent #333;
    }
  }
  &.right .tooltip-text {
    margin-left: 8px;
    left: 100%;
    bottom: 50%;
    transform: translate(0, 50%);
    &::after {
      top: 50%;
      left: -4px;
      transform: translateY(-50%);
      border-width: 6px 6px 6px 0;
      border-color: transparent #333 transparent transparent;
    }
  }
  &.top .tooltip-text {
    top: -125%;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 6px 6px 0 6px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &.bottom .tooltip-text {
    margin-top: 2px;
    top: 100%;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #333 transparent;
    }
  }
}

.global-message {
  + .app-menu {
    .nav-container {
      top: 32px;
    }
    + .mainContainer {
      .top-navbar {
        top: 32px;
      }
    }
  }
  z-index: 100;
}

.welcome-back {
  margin-bottom: 3rem;
  &-description {
    line-height: 1.2em;
    color: #777777;
  }
  &-header {
    line-height: 1.5em;
    font-size: 1.5em;
  }
  &-body {
    width: 29%;
  }
}
.tenant-name {
  line-height: 20px;
  font-weight: 600;
  color: #085873;
}