@use "../base" as *;
@use "../abstracts" as *;

.popup-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 200;

  .popup {
    border-radius: 0.3rem;
    width: 591px;
    max-height: 90vh;
    overflow: auto;
    background-color: $white;

    &__header {
      width: 100%;
      height: 50px;
      padding: 0 15px;
      background-color: #0e0a31 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        color: $white;
      }

      svg {
        cursor: pointer;
      }
    }
  }
}

.popup-body {
  padding: 25px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.popup-footer {
  border-top: 1px solid #ddd;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.popup-form {
  &__container {
    width: 100%;
    padding: 15px 40px;

    .text-area-container {
      position: relative;
      textarea {
        resize: none;
      }

      p {
        position: absolute;
        right: 5px;
        bottom: -10px;
      }
    }
  }

  &__footer {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 40px;

    button {
      margin-left: 10px;
    }
  }
}
.hidden {
  display: none;
  visibility: hidden;
}

.show-errors {
  .show-error {
    color: red !important;
  }
  .hidden {
    visibility: visible;
  }
}

.search-filter-modal {
  position: absolute;
  top: 35px;
  left: 0.6em;
  min-width: 480px;

  .modal-content {
    border-radius: 0;
  }

  .caret {
    position: absolute;
    top: -9px;
    left: 12px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ccc;

    &:after {
      content: " ";
      position: absolute;
      right: -9px;
      top: 1px;
      border-top: none;
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
      border-bottom: 9px solid #fff;
      z-index: 1;
    }
  }
}
