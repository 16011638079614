@use "../abstracts/" as *;

.date-created {
  border-left: 3px solid transparent;
  padding-left: 0.5em;

  &.order-incident {
    border-left-color: $orange;
    &.refund-issued {
      border-left-color: $brown;
    }
  }

  &.refund-issued {
    border-left-color: $tealgreen;
  }

  &.late-delivery {
    border-left-color: $danger;
  }
}

.message-wrapper {
  margin-bottom: 24px;
  max-width: 90%;
  position: relative;

  .message-avatar {
    margin: 0.75em 1.5em;
    overflow: hidden;

    img {
      height: 45px;
      width: 45px;
    }
  }
}
