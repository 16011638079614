@use "../abstracts" as *;

.modal-container {
  width: 520px;
  height: 210px;
  position: absolute;
  z-index: 20;
  bottom: -220px;
  margin-left: 10px;

  .triangle {
    width: 50px;
    height: 50px;
    background-color: #00a3c7;
    transform: rotate(45deg);
    z-index: 11;
    margin-left: 10px;
    box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.2);
  }

  &__content {
    background-color: $white;
    width: 100%;
    height: 190px;
    z-index: 12;
    position: relative;
    margin-top: -50px;
    box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.082);

    .modal-container-header {
      width: 100%;
      height: 40px;
      //background-color: $ice-grey;
    }

    .modal-container-footer {
      border-top: 1px solid #ddd;
    }

    .filter-container {
      width: 100%;
      height: 50px;
      //background-color: $ice-grey;

      :nth-child(1) {
        width: 25%;
      }

      :nth-child(2) {
        width: 25%;
      }

      .input-field {
        width: 50%;

        input {
          margin-top: 20px;
        }

        input,
        p {
          width: 100%;
        }
      }

      .show-errors {
        .show-error {
          color: red !important;
        }
      }
    }
  }
}

.modal-header {
  .btn-close {
    color: $white;
  }
}

.dialog.show {
  background-color: $modal-backdrop-color;
  display: block;
}

.bs-modal + .modal-backdrop.show {
  opacity: 0; // Overrides the default BS styles for backdrop background that do not use the `<Dialog>` component.
}

.modal-body {
  text-align: left;
}

.dialog-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $modal-backdrop-color;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 200;

  .dialog-header {
    background-color: $darkpurple;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
