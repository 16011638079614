.product-details-wrapper {
  width: 100%;
}

.product-data {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 0 1em;

  .product-data-item:not(:first-child):before {
    color: #dee2e6;
    float: left;
    padding-right: 0;
    content: "|";
    margin: 0 0.5em;
  }
}

.product-wizard {
  .mapping-from,
  .mapping-to,
  .unmapped-fields,
  .confirmed-mapping,
  .no-confirmed-mapping {
    max-width: 40%;
    position: relative;
    padding: 0.5em 1em;
    flex-grow: 1;
  }
  .mapping-to {
    max-width: 60%;
  }

  .mapping-from,
  .unmapped-fields {
    background-color: #e9effa;

    &:after {
      content: "";
      border-top: 1.25em inset transparent;
      border-bottom: 1.25em inset transparent;
      border-left: 1em solid #e9effa;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -15px;
      z-index: 1;
    }

    &.sub-category-mapping {
      background-color: #e9effa;

      &:after {
        border-left-color: #e9effa;
      }
    }

    &.category-mapping {
      background-color: #e9effa;

      &:after {
        border-left-color: #e9effa;
      }
    }
  }

  .mapping-to {
    background-color: #f5f7f8;
    border: 1px solid #dee2e6;
    padding-left: 2em;

    &.no-category-mapping {
      background-color: rgba(247, 184, 75, 0.18);
      color: #8a5906;
    }
  }

  .confirmed-mapping {
    color: #198754;
  }

  .no-confirmed-mapping {
    color: #f1556c;
  }
  .no-mapping-fields {
    color: #98a7b5;
  }
}

.product-image-thumbnails {
  .thumbnails {
    border: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;

    .img-thumbnails {
      max-width: 100px;
      max-height: 80px;
    }

    .btn-link {
      position: absolute;
      top: -19px;
      right: -21px;
    }
  }
}

.product-wrapper {
  .product-image {
    max-height: 80px;
    overflow: hidden;
  }
}

.product-attrs-scroll {
  max-height: 500px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .product-data {
    .product-data-item:not(:first-child):before {
      content: none;
    }
  }
}

#global_mapping_form .table .mb-3 {
  margin-bottom: 0 !important;
}

#product-detail-form .mfr-btn-input .form-control[readonly] {
  background-color: white !important;
}
