@use "../abstracts" as *;

.page-item {
  &.active {
    .page-link {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .page-link {
    color: $primary;
  }
}
