@use "../abstracts" as *;

.locationIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $gray-300;
  color: $bluegreen;
  border: none;
  font-size: 1.25rem;
}
.show-dropdown {
  display: block;
  right: 50%;
}

.text-area-container {
  position: relative;
  textarea {
    resize: none;
    height: 120px;
  }

  p {
    position: absolute;
    right: 5px;
    bottom: -10px;
  }
}

.bg-disabled {
  background-color: #6c757d;
}

.assign-delivery-method-container {
  .text-area-container {
    position: relative;
    textarea {
      resize: none;
      height: 150px;
    }

    p {
      position: absolute;
      right: 5px;
      bottom: -10px;
    }
  }
}

.delivery-methods-modal {
  overflow: hidden;
}

.input-search {
  position: relative;

  i {
    position: absolute;
    top: 7px;
    right: 10px;
  }

  .delivery-methods-results {
    height: 100px;
    overflow-y: scroll;
  }
}

.table {
  i {
    cursor: pointer;
  }

  .column-center {
    text-align: center;
  }
}

.zone-name-wrapper {
  .badge {
    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }
}

