.chart {
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 75%;
  width: 90%;
  margin: 3em auto 0;
  position: relative;
  gap: 3em;

  .chart-title {
    color: #777;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 30%;
    transform: translateY(-30%);
    width: 100%;
  }

  &.placeholder-glow {
    .col-0 {
      width: 20px;

      &:first-child {
        margin-left: 50px;
      }
    }
  }
}

.height-1 {
  height: 10px;
}
.height-2 {
  height: 20px;
}
.height-3 {
  height: 30px;
}
.height-4 {
  height: 40px;
}
.height-5 {
  height: 50px;
}
.height-6 {
  height: 60px;
}
.height-7 {
  height: 70px;
}
.height-8 {
  height: 100px;
}
.height-9 {
  height: 120px;
}
.height-10 {
  height: 130px;
}
.height-11 {
  height: 150px;
}
.height-12 {
  height: 170px;
}
.height-100 {
  height: 100%;
}
