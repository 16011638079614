.toast-alert__container {
  width: 400px;
  position: fixed;
  bottom: 20px;
  z-index: 500;

  .alert {
    padding: 0.25em 1em;
  }
}
