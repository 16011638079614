.toolbar-emulation {
  top: 71px;
  left: 0;
  right: 0;
}

.toolbar {
  right: 0;
  top: 0;
  z-index: 100;

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }

  .user-image {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
  }

  .online-badge {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    bottom: 0;
    right: 0;
  }

  .search-input {
    appearance: none;
    outline: none;
    box-shadow: none;
    border: none !important;
    transition: none;
  }
}
