@use "../abstracts" as *;

:root {
  --navbg: #0e0a31;
  --navlink: #ffffff;
  --navlinkbg: #286090;
  --navlinkbgActive: #00a3c7;
  --mainbg: #f5f5f5;
  --mainlink: #00a3c7;
  --mainlinkHoverBG: #00a3c7;
  --primaryButtonBGColor: #074b62;
  --primaryButtonBorderColor: #074b62;
  --primaryButtonBorderHoverColor: #0b7599;
  --primaryButtonBGHoverColor: #0b7599;
  --primaryButtonTextColor: #ffffff;
  --primaryButtonTextHoverColor: #ffffff;
  --secButtonBGColor: #00a3c7;
  --secButtonBorderColor: #00a3c7;
  --secButtonBorderHoverColor: #00a3c7;
  --secButtonBGHoverColor: #ffffff;
  --secButtonTextColor: #ffffff;
  --secButtonTextHoverColor: #00a3c7;
}
/* GLOBALS */
html {
  box-sizing: border-box;
  position: relative;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Barlow", sans-serif;
  background-color: var(--mainbg);
  font-size: 100%; // make font 100% based on browser
}

.logo-container {
  height: auto;
  max-height: 125px;

  img {
    width: 100%;
    max-height: 125px;
    object-fit: contain;

    &.logo-small {
      max-height: 125px;
    }
  }
}

.site-logo-container {
  img {
    height: 90px;
  }
}
.seller-logo-container {
  img {
    height: 120px;
    padding: 1rem;
    background-color: #dee2e6;
  }
}

.App {
  position: relative;
  width: 100%;
}

.main-container {
  padding-top: 90px;
  margin-bottom: 5em;
  margin-left: 240px;

  a {
    color: $secondary;

    &.btn-primary,
    &.btn-secondary {
      color: $white; // set color to `<a>` elements that and not `<button>`s
    }
  }

  .no-decoration {
    a {
      &:hover {
        text-decoration: none;
        background: transparent;
      }
    }
  }

  &.hide,
  &.collapse-left-padding {
    margin-left: 80px;
  }
}

.thematic-break {
  margin: 0 0.5rem;
  opacity: 80% !important;
  width: calc(100% - 1rem);
  background-color: $teal;
}

.logo-container {
  .logo-small {
    width: 80px;
    height: auto;
  }
}

.avatar-div {
  width: 100px;
  height: 100px;
}

.page-title {
  font-size: 1.5rem;
}

/* CONTENT */
.content-container {
  padding: 1.5rem;
  width: 100%;
  min-height: 100%;
  min-height: 100vh;
}

/* CUSTOM PLACEHOLDERS */
.placeholder-list,
.table {
  .placeholder-glow {
    .placeholder {
      background-color: #bdc1cc;
      border-radius: 0.1875rem;
      font-size: 0.5rem;
    }
  }
}

.placeholder-list {
  .placeholder {
    margin-bottom: 1em;
  }
}

.placeholder-glow {
  .placeholder {
    background-color: #adb5bd;
  }

  .placeholder-image {
    height: 100px;
    width: 100px;
  }
}

.placeholder-search {
  .placeholder-date-filter {
    height: 38px;
    width: 191px;
    border-radius: 0.25rem;
  }

  .placeholder-search-filter {
    height: 16px;
    width: 90px;
  }

  .placeholder-search-input {
    height: 38px;
    width: 194px;
    border-radius: 0.25rem;
  }
}

.placeholder-button {
  width: 7.5em;
}

.settings-item-icon-bg {
  background-color: $bluegreen;
  width: 50px;
  height: 50px;
}

.cursor-pointer {
  cursor: pointer;
}

.no-bullets {
  list-style-type: none; /* Remove bullets */
}

.general-settings {
  & > a {
    flex: 0 50%;
  }
}

.show-errors .show-errors {
  i,
  .show-error {
    color: $danger !important;
  }
}

.link-decoration {
  .dropdown-item {
    a {
      color: $dark;
      text-decoration: none;
    }
  }

  a {
    text-decoration: underline;
    color: $teal;

    &:active,
    &:focus,
    &:hover {
      color: $link-hover-color;
    }
  }
}

.no-decoration {
  .card {
    border-color: transparent;
    border-radius: 0.8em;
  }
}

.back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.delete-disabled {
  .disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.archived-users-temp .shadow-sm {
  box-shadow: none !important;
}

.no-list {
  margin: 0;
  padding: 0;
}

.line-height-sm {
  line-height: 1.2;
}

.required::after {
  content: "*";
  color: $danger;
  margin-left: 0.25em;
}

.carousel-indicator-detail {
  margin-bottom: -20px;
}

.checked {
  color: $warning;
  font-size: 20px;
}

.unchecked {
  color: $gray-700;
  font-size: 20px;
}

.tooltip-inner {
  max-width: 100%;
  white-space: nowrap;
  text-align: left;
}

.category-card {
  .text-truncate {
    max-width: 350px;
  }
}

.sub-category-wrapper {
  .text-truncate {
    max-width: 600px;
  }
}

.error-page {
  -webkit-animation: bounceInDown 2s ease-in;
  animation: bounceInDown 2s ease-in;
  animation-delay: 0s;

  .error-number {
    font-size: 15em !important;
    color: #fcfcfc;
    margin-bottom: 5px;
    margin-top: 15px;
    text-shadow: 0 5px 4px rgb(31 45 61 / 25%);
  }

  .mini-text {
    font-size: 33px;
    font-weight: 500;
    margin-bottom: 0;
    color: #0e0a31;
  }

  .error-text {
    max-width: 60%;
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0) scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, -10px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@media screen and (max-width: 1650px) {
  .category-card {
    .text-truncate {
      width: 260px;
    }
  }

  .sub-category-wrapper {
    .text-truncate {
      max-width: 500px;
    }
  }
}

@media screen and (max-width: 1580px) {
  .category-card {
    .subcategory-link {
      text-align: left !important;
    }
  }
  .category-card {
    .text-truncate {
      width: 300px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .category-card {
    .text-truncate {
      width: 180px;
    }
  }

  .sub-category-wrapper {
    .text-truncate {
      max-width: 215px;
    }
  }
}

// setting width on screen sizes smaller than 1080.
@media screen and (max-width: 1080px) {
  .sub-category-wrapper {
    .text-truncate {
      max-width: 150px;
    }
  }
}

@media (max-width: 575px) {
  .flex-xs-column {
    flex-direction: column !important;

    div:not(.btn-group) {
      .btn {
        flex: 1 1 auto;
        width: 100%;
        margin: 0 !important;

        &:first-child {
          margin-bottom: 0.5em !important;
        }
      }
    }
  }
}

/* Additional Helpers */
.flex-row {
  flex-direction: row !important;
}

.list-inline {
  display: inline-block;
  li {
    display: inline-block;
  }
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.vw5 {
  width: 5vw !important;
}

.v-5,
.width-5 {
  width: 5% !important;
}

.vw10 {
  width: 10vw !important;
}

.w-10,
.width-10 {
  width: 10% !important;
}

.vw15 {
  width: 15vw !important;
}

.w-15,
.width-15 {
  width: 15% !important;
}

.vw20 {
  width: 20vw !important;
}

.w-20,
.width-20 {
  width: 20% !important;
}

.vw25 {
  width: 25vw !important;
}

.w-25,
.width-25 {
  width: 25% !important;
}

.vw30 {
  width: 30vw !important;
}

.w-30,
.width-30 {
  width: 30% !important;
}

.vw35 {
  width: 35vw !important;
}

.w-35,
.width-35 {
  width: 35% !important;
}

.vw40 {
  width: 40vw !important;
}

.w-40,
.width-40 {
  width: 40% !important;
}

.width-40px {
  width: 40px !important;
}

.vw45 {
  width: 45vw !important;
}

.w-45,
.width-45 {
  width: 45% !important;
}

.vw50 {
  width: 50vw !important;
}

.w-50,
.width-50 {
  width: 50% !important;
}

.vw55 {
  width: 55vw !important;
}

.w-55,
.width-55 {
  width: 55% !important;
}

.vw60 {
  width: 60vw !important;
}

.w-60,
.width-60 {
  width: 60% !important;
}

.vw65 {
  width: 65vw !important;
}

.w-65,
.width-65 {
  width: 65% !important;
}

.vw70 {
  width: 70vw !important;
}

.w-70,
.width-70 {
  width: 70% !important;
}

.vw75 {
  width: 75vw !important;
}

.w-75,
.width-75 {
  width: 75% !important;
}

.vw80 {
  width: 80vw !important;
}

.w-80,
.width-80 {
  width: 80% !important;
}

.vw85 {
  width: 85vw !important;
}

.w-85,
.width-85 {
  width: 85% !important;
}

.vw90 {
  width: 90vw !important;
}

.w-90,
.width-90 {
  width: 90% !important;
}

.vw95 {
  width: 95vw !important;
}

.w-95,
.width-95 {
  width: 95% !important;
}

.vw100 {
  width: 100vw !important;
}

.w-100,
.width-100 {
  width: 100% !important;
}

.width-80px {
  width: 80px !important;
}

.width-100px {
  width: 100px !important;
}

.vw-auto,
.w-auto,
.width-auto {
  width: auto !important;
}

.mh45 {
  min-height: 45px !important;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner-border-lg {
  width: 3rem;
  height: 3rem;
}

.search-input-loader-icon {
  color: #777;
  width: 1rem;
  height: 1rem;
  margin-right: 0.6em;
  margin-top: 0.25em;
}

@media print {
  body {
    line-height: 1.2;
    font-size: 10pt;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 10pt;
  }

  .app-menu,
  header,
  .return-header,
  .page-title,
  .order-messages-wrapper,
  .order-secondary-buttons,
  .order-information .bi {
    display: none !important;
  }

  .card {
    box-shadow: none !important;
    border: 0 !important;
  }

  .order-information {
    & > div {
      flex-direction: column !important;
    }
  }

  .main-container {
    padding-top: 0;
    margin-left: 0;

    &.collapse-left-padding {
      margin-left: 0;
    }
  }
}

.category-wrapper {
  min-width: 4.2rem;
}

.progress-container {
  position: relative;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: #6c757d;
    z-index: 10;
  }
}

.category-fullname {
  max-width: 26rem;
}
