.container-overlay {
  position: relative;
  max-height: 100px;
  max-width: 100px;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 35%;
  background-color: transparent;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 10px;
  text-align: center;
}

.container-overlay:hover .overlay {
  opacity: 1;
}

select[name="landingPageId"] {
  max-width: 300px;
  margin-left: 10px;
}
