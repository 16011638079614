/*
  Variables should follow the `$component-state-property-size` formula for
  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
*/

// Colors
// gray-color-variables
$white: #fff;
$gray-100: #f1f5f7;
$gray-200: #f5f5f5;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #98a6ad;
$gray-700: #6c757d;
$gray-800: #343a40;
$gray-900: #333;
$black: #000;

// gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
);

// MarketPush primary color variables
$mp-blue: #0e0a31;

// blues and purples
$blue: #0d6efd;
$astral: #337ab7;
$bluegreen: #085873;
$blurple-blue: #0e0a31; //navbar
$pacific-blue: #00a3c7;
$cyan: #37cde6;
$purple: #6559cc;
$darkpurple: #0e0a31;

// reds and browns
$brown: #a52a2a;
$conifer: #badc58;
$indigo: #6610f2;
$teal: #00a3c7;

// yellows and oranges
$pink: #f672a7;
$red: #f1556c;
$orange: #fd7e14;
$yellow: #f7b84b;

// greens
$green: #198754;
$emerald-green: #50c878;
$kelly-green: #00d100;
$pastel-green: #77dd77;
$teal-green: #008080;
$teal-blue: #07495f;
$tealgreen: #20c997;
$turquoise: #40e0d0;
$lime: #badc58;

// Theme color variables
$primary: $bluegreen;
$secondary: $teal;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "pink": $pink,
  "dark-purple": $darkpurple,
  "white": $white,
  "orange": $orange,
);

// Theme to RGB
// $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

// Set the contrast ratio to reach against white for accessibility.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.7;

// Customize the light and dark text colors
$color-contrast-dark: $gray-800;
$color-contrast-light: $white;

// Prefix for :root CSS variables
$variable-prefix: mp-;

/* 
  Gradient added to components if `$enable-gradient` is `true`
  This gradient is also added to elements with `.bg-gradient`
*/
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0));

// Settings for `<body>` element.
$body-bg: #f5f5f5;
$body-color: $gray-800;
$body-text-align: null;

// Links
$link-color: $secondary;
$link-decoration: underline;
$link-shade-percentage: 22%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: underline;

// Typography
$font-family-sans-serif: "Barlow", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-base: $font-family-sans-serif;
$font-family-code: var(--#{variable-prefix}font-monospace);

// $font-size-root effects the value of `rem`, which can may used for font sizes, padding, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 0.9rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.9;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-book: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-normal;

// $modal background color
$modal-backdrop-color: rgba(0, 0, 0, 0.5);

// Border radius sizes and more.
$border-width: 1px;
$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
);

$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;
$border-radius-pill: 50rem;

// Pagination variables to override default Bootstrap
$pagination-padding-y: 0.375rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-color: $dark;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-radius: $border-radius;
$pagination-margin-start: -$pagination-border-width;
$pagination-border-color: $gray-300;
$pagination-focus-color: $link-hover-color;
$pagination-focus-bg: $gray-200;
$pagination-focus-outline: 0;
$pagination-hover-color: $dark;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;
$pagination-active-color: $white;
$pagination-active-bg: $bluegreen;
$pagination-active-border-color: $bluegreen;
$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;
$pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$pagination-border-radius-sm: $border-radius-sm;
$pagination-border-radius-lg: $border-radius-lg;

/* Tooltips */
$tooltip-max-width: 700px;
$tooltip-font-size: $font-size-sm;
$tooltip-bg: $bluegreen;
