@use "../abstracts" as *;

@media (min-width: 1200px) {
  .general-settings > a {
    flex: 0 33%;
  }
}

@media (max-width: 1111px) {
  .administration-card-link,
  .general-setting-card-link {
    width: 100%;
  }

  .administration-card,
  .general-setting-card {
    width: 100% !important;

    :nth-child(2) {
      text-align: left;
    }
  }
}

.general-setting-card-link,
.administration-card-link {
  text-decoration: none;
  width: 430px;

  .administration-card,
  .general-setting-card {
    width: 100% !important;
    min-height: 100px;
  }

  .bi,
  .card-title {
    color: $bluegreen;
    text-align: left;
    font-weight: 500 !important;
  }
}

.general-settings-container {
  a {
    text-decoration: none; // Removing underlines from default style
  }
}

.select-box {
  &:hover {
    cursor: pointer;
  }
}
