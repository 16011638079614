.arrow-step {
  background-color: #d9e3f7;
  text-align: center;
  cursor: default;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 180px;
  float: left;
  position: relative;

  &.current,
  &.done {
    color: #fff;
    background-color: #337ab7;

    &::after {
      border-left: 17px solid #337ab7;
    }

    &:last-child {
      background-color: #198754;

      &::after {
        border-left: 17px solid #198754;
      }
    }
  }
  &.failed {
    &:last-child {
      color: #842029;
      background-color: #f8d7da;

      &::after {
        border-left: 17px solid #f8d7da;
      }
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 17px solid #d9e3f7;
    z-index: 2;
  }

  &:before {
    border-left: 17px solid #fff;
    right: auto;
    left: 0;
    z-index: 0;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    &:before {
      border: none;
    }
  }

  span {
    position: relative;
  }
}