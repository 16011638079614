@use "../abstracts" as *;
@use "../base" as *;

.datepicker-container {
  width: 600px;
  height: 550px;
  position: absolute;
  z-index: 20;
  bottom: -560px;
  margin-left: 10px;

  .triangle {
    width: 50px;
    height: 50px;
    background-color: $white;
    transform: rotate(45deg);
    z-index: 11;
    margin-left: 10px;
    box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.2);
  }

  &__content {
    background-color: $white;
    width: 100%;
    height: 550px;
    z-index: 12;
    position: relative;
    margin-top: -50px;
    box-shadow: 5px 6px 16px rgba(17, 17, 17, 0.082);

    .react-calendar {
      border: none;
      font-family: "Barlow";

      .react-calendar__tile--active {
        background-color: $atoll;
        border-radius: 5px;
      }

      .react-calendar__navigation__label {
        font-weight: 700;
      }

      abbr {
        text-decoration: none;
      }

      .react-calendar__month-view__days__day--weekend
        .react-calendar__month-view__days__day--neighboringMont {
        color: $gray-300 !important;
      }
    }

    .modal-container-header {
      width: 100%;
      height: 40px;
      background-color: $ice-grey;
    }

    .filter-container {
      width: 100%;
      height: 50px;
      background-color: $ice-grey;

      :nth-child(1) {
        width: 25%;
      }

      :nth-child(2) {
        width: 25%;
      }

      .input-field {
        width: 50%;

        input {
          margin-top: 20px;
        }

        input,
        p {
          width: 100%;
        }
      }

      .show-errors {
        .show-error {
          color: $danger !important;
        }
      }
    }
  }
}

.weekly-elements {
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 0.25rem;
  cursor: pointer;
  color: $primary;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  margin-right: 0.25rem;
}

.weekly-elements:last-child {
  margin-right: 0;
}

.weekly-on-element {
  background-color: $primary;
  color: $white;
}

.table-floating-card__container {
  .btn {
    background-color: $primary !important;
  }
}

.rdrDayToday {
  .rdrDayNumber {
    span {
      &:after {
        background-color: $primary;
      }
    }
  }

  &:not(.rdrDayPassive) {
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected {
      & ~ .rdrDayNumber {
        span {
          &:after {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.rdrDateDisplayItemActive {
  border-color: $primary !important;
  box-shadow: 0 0 0 0.25rem rgba(8, 88, 115, 0.25) !important;
}

.rdrDay {
  .rdrInRange {
    background-color: rgba(8, 88, 115, 0.5);
  }

  &.rdrDayHovered {
    span {
      border-color: $primary !important;
      border-radius: 0;
    }
  }

  .rdrDayInPreview,
  .rdrDayEndPreview,
  .rdrDayStartPreview {
    border-color: $primary;
    border-radius: 0;
  }

  &.rdrDayEndOfWeek,
  &.rdrDayStartOfWeek {
    .rdrInRange {
      background-color: rgba(8, 88, 115, 0.5);
      border-radius: 0;
    }
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background-color: rgba(8, 88, 115, 0.5);
    border-radius: 0;
  }
}
