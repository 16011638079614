@use "../abstracts" as *;

.cardSize {
  height: 100%;

  .cardHeader {
    background-color: $gray-500;
  }
}

.card {
  width: 100%;

  .card-body {
    padding: 1.5rem;
  }

  .card-title {
    font-weight: $font-weight-book !important;
  }

  .card-text {
    .placeholder {
      height: 2em;

      &.placeholder-small {
        height: 0.75em;
      }
    }

    .placeholder-description {
      height: 7em;
    }
  }

  .header-title {
    font-weight: 400 !important;
  }

  &.no-top-border {
    border-top: 0 !important;
  }

  &.card-warning {
    border-color: #f7bb55;

    .card-header {
      background-color: #f7bb55;
      border-bottom-color: transparent !important;
      color: #434343;
    }

    .card-body {
      background-color: rgba(247, 184, 75, 0.1);
      border-color: #f7bb55;
    }
  }
}

.img-placeholder {
  height: 60px;
  width: 60px;

  .bi-card-image {
    font-size: 3rem;
    color: $gray-500;
  }
}
