@use "../abstracts" as *;

.badge {
  padding-bottom: 0.45em;
  font-weight: $font-weight-book;

  &.badge-sm {
    font-size: $font-size-sm;
  }
}
