@import "../base/";
@import "../abstracts";

.form-control {
  &.with-icon {
    padding-left: 30px;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(8, 88, 115, 0.25);
    border: 1px solid $atoll !important;
  }

  &.is-invalid {
    border-color: $danger !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;

    &:focus {
      box-shadow: 0px 0px 6px #fb2b8487 !important;
    }
  }

  &.custom-css {
    &::placeholder {
      opacity: 0.4;
    }
  }
}

.form-select {
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(8, 88, 115, 0.25);
    border: 1px solid $atoll !important;
  }
}

// Forms and Inputs styles
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.form-check-input {
  &:checked,
  [type="checkbox"] {
    background-color: $primary;
    border-color: $primary;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(8 88 115 / 25%);
    border-color: #085873;
  }
}

.form-switch {
  .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%233b484d%27/%3e%3c/svg%3e");
    
    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23085873%27/%3e%3c/svg%3e");
    }
    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    }
  }
}

.select-box {
  width: 100%;
  height: 100px;
  background-color: $light-grey;
  margin-top: 6px;
  text-align: center;
  line-height: 100px;
}

.form-dropdown {
  position: absolute;
  background-color: #fff;
  display: none;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.2);
  border-radius: 3px;
  &.active {
    display: block;
  }
}

.parent-option {
  &-img {
    width: 1.5rem;
    height: auto;
  }
  .nested-child-option {
    min-height: 3rem;
    max-height: 4.5rem;
    border-bottom: 1px solid #ddd;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    min-height: 3rem;
    max-height: 4.5rem;
    align-items: center;
    border-bottom: 1px solid #eee;
    border-radius: 4px 4px 0 0;
    padding: 5px;
    transition: 0.4s;
    &:hover {
      background-color: #fafafa;
    }
  }
}

.nested-select-body {
  display: none;
  &.active {
    display: block;
  }
}
