[data-title] {
  position: relative;
  &:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }

  &::after {
    content: attr(data-title);
    background-color: #020202;
    color: rgb(252, 250, 250);
    position: absolute;
    padding: 3px;
    font-size: small;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 1054;
    visibility: visible; //hidden;
    border-radius: 5px;
    font-style: normal;
    left: var(--left-length, 70%);
  }
  &.tooltip-top::after {
    left: var(--left-length, 0);
    bottom:2em;
  }
  &.tooltip-bottom::after {
    left: var(--left-length, 0);
    bottom: -2.5em;
  }
  &.tooltip-left::after {
    left:var(--left-length, -2em);
  }
  &.tooltip-none:after {
    display:none;
  }
}
