@use "../abstracts" as *;
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap");

$placeholder-opacity-max: 0.2;
$placeholder-opacity-min: 0.1;

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2rem !important;
  }

  h2,
  .h2 {
    font-size: 1.75rem !important;
  }

  h3,
  .h3 {
    font-size: 1.51rem !important;
  }

  h4,
  .h4 {
    font-size: 1.32rem !important;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: $font-weight-semi-bold !important;
}

// Display sizes
.display-1 {
  font-size: 2rem !important;
}

.display-2 {
  font-size: 1.75rem !important;
}

.display-3 {
  font-size: 1.51rem !important;
}

.display-4 {
  font-size: 1.32rem !important;
}

.display-5 {
  font-size: 1.125rem !important;
}

.display-6 {
  font-size: 0.875rem !important;
}

.display-7 {
  font-size: 0.75rem !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-semi-bold {
  font-weight: $font-weight-book !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.text-centered {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-light-gray {
  color: $gray-700 !important;
}

.text-gray {
  color: $gray-800 !important;
}

.text-dark {
  color: $gray-900 !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-large {
  font-size: $font-size-lg;

  &.bi {
    font-size: 2em;
  }
}

.line-through {
  text-decoration: line-through !important;
}

.has-error {
  margin-top: 0.25em;
}

.sub-title {
  font-size: 1.125rem;
}

.helper-text {
  margin-bottom: 0.125rem;
}
