@import "../abstracts";
// Need to remove later.
.action-dropdownmenu {
  cursor: pointer;

  .delete-disabled {
    cursor: none;
    // color: $bluegreen;
  }

  .action-card {
    width: 8rem;
    position: absolute;
    left: -10%;
    margin-left: -3.5rem;
    z-index: 100;
    background-color: $white;
  }

  a {
    color: $gray-800;

    &:active {
      color: $white;
    }
  }

  .dropdown-item {
    &.delete-disabled {
      &:active,
      &:focus,
      &:hover {
        background-color: $white;
        //color: $bluegreen;
      }
    }
    &.active,
    &:active {
      background-color: $white;
    }
  }
}

//for dropdown element, might remove later
.action-dropdownmenu {
  .action-dropup {
    bottom: 110%;
  }
}

.action-dropdownmenu {
  .action-dropdown {
    top: 110%;
  }
}

.show-menu {
  display: block;
}

.dropdown-menu-md {
  min-width: 225px;
}

.dropdown-menu-lg {
  min-width: 250px;
  max-width: 300px;
  padding: 0;

  .notification-menu-body {
    padding: 0.5em 0.8em;

    .notification-item {
      border: 0;
      border-bottom: 1px solid #ddd;
      border-radius: 0;
    }
  }
}

.row-element {
  .dropdown-item {
    &.active,
    &:active {
      background-color: transparent;
      color: $gray-800;
    }
    & > * {
      // making sure that all children are styled correctly
      // color: $gray-800;
      text-transform: capitalize;
    }
  }
}

.store-selector {
  .dropdown-menu {
    margin-top: 10px !important;

    .list-group-item {
      padding: 0;
      border-color: transparent;
    }
  }
}

.notification-dropdown {
  margin-top: 2em;

  &.show {
    display: block;
    right: 0;
  }
}

.apps-dropdown {
  margin-top: 1.6em !important;
  width: 425px;

  .dropdown-item {
    &.active {
      background-color: #e9ecef;
      color: #1e2125;
    }
  }

  .marketplace-apps {
    .bi-shop {
      background-color: #e9ecef;
      border-radius: 50%;
      height: 2em;
      width: 2em;
      padding: 0.25em;
      text-align: center;
    }
  }
}

.user-menu {
  &.show {
    display: block;
    right: 0;
  }

  a {
    text-decoration: none !important;
  }
}

.dropdown-item {
  &:active {
    color: #343a40;
    background-color: transparent;
  }
}
