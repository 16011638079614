

.message {
    &__text {
        max-width: 25vw;
    }
    &__date-created {
        border-left: 3px solid transparent;
        padding-left: 0.5em;
        min-width: 10vw;
    }
    &__customer-email {
        min-width: 15vw;
    }
    &__order-number {
        min-width: 5vw;
    }
    &__seller-item-sku {
        min-width: 15vw;
    }
}