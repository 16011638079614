.chips {
  .chip {
    background-color: #ddd;
    &:focus {
      background-color: #bbb !important;
    }
  }
  .selected {
    background-color: #bbb !important;
  }

  .input-wrapper {
    flex: 1 1 0%;

    input {
      outline: 0px !important;
    }
  }
}