@use "../base" as *;
//@use "../abstracts" as *;

.back-button {
  background-color: $alto;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none;
  }

  &:hover {
    background-color: darken($color: $alto, $amount: 10%);
  }

  i {
    color: $atoll;
  }
}
