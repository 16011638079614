@use "../abstracts" as *;

.tab {
  background-color: white !important;
  border-color: transparent transparent $primary transparent !important;
}

.tab-content {
  .card {
    border-top-color: transparent;
  }
}

.nav-tabs {
  .nav-link {
    margin-bottom: -1px;

    &.current.active {
      border-color: $primary $primary #ffffff !important;
      color: $primary !important;
      font-weight: 600;
      margin-bottom: -1px;

      &:hover {
        border-color: $primary $primary #ffffff !important;
        color: $primary !important;
        cursor: pointer;
        font-weight: 600;
        background-color: $white !important;
      }
    }

    &:not(:first-child) {
      margin-left: 2px;
    }

    &:hover {
      border-color: transparent transparent;
      cursor: pointer;
    }
  }

  .false {
    &:hover {
      border-color: transparent transparent $primary transparent !important;
    }
  }
}

@media (max-width: 1200px) {
  .nav-tabs {
    flex-wrap: nowrap;
  }
}
