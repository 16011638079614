.loader-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .bouncer {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 5em;
    height: 5em;

    div {
      width: 1em;
      height: 1em;
      background-color: #482985;
      border-radius: 50%;
      animation: bouncer 0.5s cubic-bezier(0.19, 0.57, 0.3, 0.98) infinite
        alternate;
    }

    div:nth-child(2) {
      background-color: #00a3c7;
      animation-delay: 0.1s;
    }

    div:nth-child(3) {
      background-color: #085873;
      animation-delay: 0.2s;
    }

    div:nth-child(4) {
      background-color: #eade3d;
      animation-delay: 0.3s;
    }
  }

  @keyframes bouncer {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-4em);
    }
  }
}
