@use "../abstracts/" as *;

.menu-navs {
  .accordion-item {
    background-color: transparent;

    .accordion-button {
      background-color: transparent;
      border-radius: 0;
      color: var(--navlink);
      font-weight: 600;
      padding: 0.75rem 1rem;

      &:focus {
        box-shadow: none;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
      }

      &:not(.collapsed) {
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        }
      }
    }
  }

  .accordion-body {
    padding: 0;

    .nav-link {
      font-size: 0.85rem;
      font-weight: 400;

      span {
        margin-left: 0.15em;

        .bi {
          margin-right: 1.125em !important;
        }
      }
    }
  }
}

.product-carousel-wrapper {
  height: 250px;
  width: 325px;
  overflow: hidden;

  .carousel-item {
    height: 200px;

    img {
      max-width: 180px;
      max-height: 180px;
    }
  }

  .carousel-indicator-detail {
    margin-bottom: -30px;
  }
}
